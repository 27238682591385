<!-- Google tag (gtag.js) -->
<script async src="https://www.googletagmanager.com/gtag/js?id=G-3QFRYTYGL3"></script>
<script>
  window.dataLayer = window.dataLayer || [];
  function gtag() { dataLayer.push(arguments); }
  gtag('js', new Date());

  gtag('config', 'G-3QFRYTYGL3');
</script>

<header>
  <section class="navigation">
    <div class="nav-container">
      <div class="brand">
        <a class="image-link">
          <img src="/assets/logo-cunori.png" class="image" alt="Logo Cunori">
          <img src="/assets/logo_ingenieria.png" class="image" alt="Logo Ingeniería">
          Pruebas Específicas de Ingeniería v1.0
        </a>
      </div>
      <nav>
        <ul class="nav-list">
          <li><a href="/estudiantes" class="nav-link">
              <mat-icon>keyboard_backspace</mat-icon> Atrás
            </a></li>
        </ul>
      </nav>
    </div>
  </section>
</header>

<mat-card>
  <mat-card-content>
    <div class="container">
      <br>
      <h1 class="h1-bold-center">{{ titulo }}</h1>
      <br>
      <div class="input-group">
        <input type="file" class="form-control" name="file" ng2FileSelect [uploader]="uploader" aria-label="Upload"
          #inputFile accept=".xlsx, .xls" />
        <button class="btn btn-warning align-icon" type="button" (click)="uploadAllWithLoading()">
          <mat-icon> attach_file</mat-icon> Subir archivo
        </button>
      </div>
      <br>
      <br>
    </div>
  </mat-card-content>
  <mat-card-footer>
    <mat-progress-bar mode="indeterminate"></mat-progress-bar>
  </mat-card-footer>
</mat-card>