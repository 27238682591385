import { Prueba } from "./prueba";

export class Estudiante {
    id!:number;
    nov!: number;
	nombre!: String;
	cui!: String;
	correo!: String;
	ua!: number;
	ext!: number;
	car!: number;	
	pruebas!: Prueba[];
	resultadoGeneral?: string; 
}
