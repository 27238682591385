import { Component, OnInit } from '@angular/core';
import { EstudiantesService } from 'src/app/services/estudiante.service';
import { Estudiante } from 'src/app/model/estudiante';
import { Prueba } from 'src/app/model/prueba';
import { PruebaService } from 'src/app/services/prueba.service';
import { LoginService } from './../../services/login.service';
import Swal from 'sweetalert2';
import { mergeMap } from 'rxjs/operators';

@Component({
  selector: 'app-estudiantes',
  templateUrl: './estudiantes.component.html',
  styleUrls: ['./estudiantes.component.scss']
})
export class EstudiantesComponent implements OnInit {
  titulo: string = "Lista de Estudiantes";
  terminoBusqueda: string = '';
  estudiantes!: Estudiante[];
  pruebas!: Prueba[];
  estudiante: Estudiante = new Estudiante();
  prueba: Prueba = new Prueba();

  constructor(public estudianteService: EstudiantesService,
    public pruebaService: PruebaService,
    public login:LoginService) { }

    ngOnInit(): void {
      this.estudianteService.listar().subscribe(
        e => {
          this.estudiantes = e;
        }
      );
      this.pruebaService.listar().subscribe(
        p => {
          this.pruebas = p;
        }
      );
    }    

    eliminar(prueba: Prueba, estudiante: Estudiante): void {
      Swal.fire({
        title: '¿Está segur@?',
        text: "¡No podrás revertir esto!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        cancelButtonText: 'Cancelar',
        confirmButtonText: '¡Sí, bórralo!'
      }).then((result) => {
        if (result.isConfirmed) {
          if (prueba.id !== undefined && estudiante.id !== undefined) {
            this.pruebaService.eliminar(prueba.id).pipe(
              mergeMap(() => this.estudianteService.eliminar(estudiante.id))
            ).subscribe(
              () => {
                Swal.fire(
                  '¡Eliminado!',
                  'Se ha borrado la prueba y el estudiante',
                  'success'
                ).then(() => {
                  // Actualizar la lista de pruebas
                  this.pruebaService.listar().subscribe(
                    response => this.pruebas = response
                  )
                  // Actualizar la lista de estudiantes
                  this.estudianteService.listar().subscribe(
                    response => this.estudiantes = response
                  )
                });
              },
              error => {
                //console.error('Error al eliminar', error);
                Swal.fire({
                  title: 'Error!',
                  html: `Ocurrió un error al eliminar. <br> Tienes que eliminar todas las pruebas del estudiante asociado para poder eliminar al estudiante<br>`,
                  icon: 'error'
                })
              }
            );
          } else {
            //console.error('Error: prueba.id o estudiante.id es undefined');
          }
        }
      })
    }
                    
  
    buscar() {
      if (this.terminoBusqueda.trim() !== '') {
        //console.log('Búsqueda realizada: ', this.terminoBusqueda);
        // Realizar la búsqueda solo si hay un término de búsqueda válido
        this.estudianteService.listar().subscribe(estudiantes => {
          this.estudiantes = estudiantes.filter(estudiante => {
            // Filtrar los estudiantes cuyo nombre, carrera o resultado incluyen el término de búsqueda (ignorar mayúsculas/minúsculas)
            const termino = this.terminoBusqueda.trim().toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "");
            return (
              estudiante.nombre.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "").includes(termino) ||
              estudiante.nov.toString().includes(termino) ||
              estudiante.car.toString().includes(termino)
            )
          });
          this.pruebaService.listar().subscribe(pruebas => {
            this.pruebas = pruebas.filter(prueba => {
              // Filtrar las pruebas cuyo resultado o nombrePrueba incluyen el término de búsqueda (ignorar mayúsculas/minúsculas)
              const termino = this.terminoBusqueda.trim().toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "");
              return prueba.resultado.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "").includes(termino) || 
              prueba.nombrePrueba.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "").includes(termino);
            });
          });
        });
      } else {
        // Si no hay término de búsqueda, mostrar todos los estudiantes y pruebas nuevamente
        this.estudianteService.listar().subscribe(estudiantes => {
          this.estudiantes = estudiantes;
          //console.log('Mostrando todos los resultados');
        });
        this.pruebaService.listar().subscribe(pruebas => {
          this.pruebas = pruebas;
          //console.log('Mostrando todos los resultados');
        });
      }
    }    

  borrarBusqueda() {
    this.terminoBusqueda = ''; // Limpiar el término de búsqueda
    this.estudianteService.listar().subscribe(estudiantes => {
      this.estudiantes = estudiantes; // Mostrar todos los estudiantes nuevamente
      //console.log('Búsqueda borrada, Mostrando todos los resultados');
    })
    this.pruebaService.listar().subscribe(pruebas => {
      this.pruebas = pruebas; // Mostrar todos los estudiantes nuevamente
      //console.log('Búsqueda borrada, Mostrando todos los resultados');
    })
  }

  public logout(){
    this.login.logout();
    window.location.reload();
    console.log("Ha cerrado sesión el administrador");
  }

//fin
}
