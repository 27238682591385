import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Estudiante } from '../model/estudiante';

@Injectable({
  providedIn: 'root'
})

export class EstudiantesService {

  //produccion 
  public URL:string="https://api-especificas.ingsistemascunori.org/api/estudiantes"
  //public URL:string="http://localhost:8080/api/estudiantes" 

  constructor(public http:HttpClient) { }

  //obtener todos los estudiantes
  listar(): Observable<Estudiante[]>{
    return this.http.get<Estudiante[]>(this.URL);
  }
  //crear estudiante
  create(estudiante:Estudiante):Observable<Estudiante>{
    return this.http.post<Estudiante>(this.URL, estudiante)
  }
  //obtener un estudiante
  getId(id:number):Observable<Estudiante>{
    return this.http.get<Estudiante>(this.URL+'/estudiantePorId/'+id)
  }
  //actualizar estudiante
  update(estudiante: Estudiante):Observable<Estudiante>{
    return this.http.put<Estudiante>(this.URL, estudiante)
  }
  //eliminar un estudiante
  eliminar(id:number):Observable<Estudiante>{
    return this.http.delete<Estudiante>(this.URL+'/'+id)
  }
  // Crear una lista de estudiantes
  createList(estudiantes: Estudiante[]): Observable<any> {
    return this.http.post<any>(`${this.URL}/importados`, estudiantes);
  }  
  //obtener pruebas de un estudiante
  getNov(nov:number):Observable<Estudiante[]>{
    return this.http.get<Estudiante[]>(this.URL+'/estudiantePorNov/'+nov)
  }
  //obtener un estudiante por su nombre
  getNombre(nombre:String):Observable<Estudiante>{
    return this.http.get<Estudiante>(this.URL+'/estudiante/'+nombre)
  }

//fin  
}
